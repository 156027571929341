import * as React from "react";
import Card from "react-bootstrap/Card";
import Images from "react-bootstrap/Image";
import { productListCard } from "./layout.module.css";

export type productGridCardModal = {
  thumbnailURL: string;
  productName: string;
  productCode: string;
  clickHandler: React.MouseEventHandler;
};

const ProductGridCard = (vm: productGridCardModal) => {
  return (
    <Card className={productListCard} border="secondary" onClick={vm.clickHandler}>
      <Card.Body>
        <Images
          src={vm.thumbnailURL}
          style={{ width: "70px", height: "70px" }}
          rounded
        />
        <br />
        <br />
        {vm.productName}
        <br />
        {vm.productCode}
      </Card.Body>
    </Card>
  );
};

export default ProductGridCard