import * as React from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Images from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import Layout from "../components/layout";
import queryString from "query-string";
import {
  useForm,
  Controller,
  SubmitHandler,
  FieldError,
  FieldErrors,
} from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import {
  headerTitle,
  formTitleLabel,
  wrongModelText,
  inputFormContainer,
  walnutPrimaryButton,
  polygonVerticalCenterHorizontalRight,
  productListModal,
  productListModalSelectProductModelText,
} from "../components/layout.module.css";
import { StaticImage } from "gatsby-plugin-image";
import ProductGridCard from "../components/product-grid-card";
import { navigate, PageProps } from "gatsby";
import Swal from "sweetalert2";

type Product = {
  name: string;
  code: string;
  thumbnail: string;
};

interface IFormInput {
  productModelCode: string;
  productLotNumber: string;
  // manufacturingDate: string;
  // expiryDate: string;
}

export interface VerifiedProductItem {
  productItemId: string;
  productName: string;
  productCode: string;
  warrantyPeriod: number;
  thumbnail: string;
}

const selectedProductCard = (
  product?: Product,
  errors?: FieldErrors,
  handleShow?: (event: React.MouseEvent<HTMLDivElement>) => void
) => {
  function cardBody() {
    if (product) {
      return (
        <Container fluid>
          <Row>
            <Col xs={4} style={{ padding: "0px" }}>
              <Images
                src={product.thumbnail}
                style={{ width: "100px", height: "100px" }}
                rounded
              />
            </Col>
            <Col xs={7}>
              <Container style={{ padding: "0px" }}>
                <Row>
                  <Col>{product.name}</Col>
                </Row>
                <Row>
                  <Col>{product.code}</Col>
                </Row>
                <Row>
                  <Col className={wrongModelText}>
                    Wrong model?
                    <br />
                    Change here.
                  </Col>
                </Row>
              </Container>
            </Col>
            <Col xs={1} style={{ padding: "0" }}>
              <div className={polygonVerticalCenterHorizontalRight}>
                <StaticImage
                  className="verticalCenter"
                  alt="polygon"
                  src="../images/polygon-icon.png"
                  style={{ width: "0.6em" }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      );
    } else {
      return <p>Please press here to select product model</p>;
    }
  }
  return (
    <Form.Group controlId="formProductModel">
      <Form.Label className={formTitleLabel}>Product model</Form.Label>
      <Card border="secondary" onClick={handleShow}>
        <Card.Body>{cardBody()}</Card.Body>
      </Card>
    </Form.Group>
  );
};

const IndexPage = ({ location }: PageProps) => {
  const [show, setShow] = React.useState(false);
  const [products, setProducts] = React.useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<Product>();
  const handleClose = () => setShow(false);

  const handleShow = (event: React.MouseEvent<HTMLDivElement>) => {
    axios.get(`${process.env.API_URL}/product/list`).then(function (response) {
      if (response.status == 200) {
        setProducts(response.data.products);
        setShow(true);
      }
    });
  };

  const stackProductGrid = (products: Product[]) => {
    if (products.length > 1) {
      return (
        <Row className="gx-3 gy-3">
          {products.map((product) => (
            <Col xs={{ span: 6 }}>
              <ProductGridCard
                thumbnailURL={product.thumbnail}
                productName={product.name}
                productCode={product.code}
                clickHandler={() => {
                  setSelectedProduct({
                    name: product.name,
                    code: product.code,
                    thumbnail: product.thumbnail,
                  });
                  setValue("productModelCode", product.code);
                  handleClose();
                }}
              />
            </Col>
          ))}
        </Row>
      );
    } else if (products.length == 1) {
      return (
        <Row className="gx-3 gy-3">
          <Col>
            <ProductGridCard
              thumbnailURL={products[0].thumbnail}
              productName={products[0].name}
              productCode={products[0].code}
              clickHandler={() => {
                setSelectedProduct({
                  name: products[0].name,
                  code: products[0].code,
                  thumbnail: products[0].thumbnail,
                });
                setValue("productModelCode", products[0].code);
                handleClose();
              }}
            />
          </Col>
        </Row>
      );
    }
  };

  const queryParams = queryString.parse(location.search);
  const productCode = queryParams.code;
  const lotNumber = queryParams.lotnumber;
  // const manufacturingDate = queryParams.mdate;
  // const expiryDate = queryParams.edate;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    getFieldState,
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    axios
      .post(`${process.env.API_URL}/product/item/validate`, data)
      .then(function (response) {
        if ((response.status = 200)) {
          console.log(response.data);
          const parsedData = response.data.data as VerifiedProductItem;
          console.log(parsedData);
          navigate("/disclaimer/", { state: { productState: parsedData } });
        }
      })
      .catch(function (error) {
        if (error.response) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data,
            confirmButtonColor: "#01B5BB",
          });
        } else if (error.request) {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
            confirmButtonColor: "#01B5BB",
          });
          console.log(error.request);
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.message,
            confirmButtonColor: "#01B5BB",
          });
          console.log("Error", error.message);
        }
      });
  };

  React.useEffect(() => {
    if (productCode && selectedProduct == null) {
      axios
        .get(`${process.env.API_URL}/product/select/${productCode}`)
        .then(function (response) {
          if ((response.status = 200)) {
            setSelectedProduct(response.data.product);
            setValue("productModelCode", response.data.product.code);
          }
        })
        .catch(function (error) {
          if (error.response) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.response.data,
              confirmButtonColor: "#01B5BB",
            });
          } else if (error.request) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              confirmButtonColor: "#01B5BB",
            });
            console.log(error.request);
          } else {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: error.message,
              confirmButtonColor: "#01B5BB",
            });
            console.log("Error", error.message);
          }
        });
    }
    // if (
    //   lotNumber &&
    //   typeof lotNumber === "string" &&
    //   !getFieldState("productLotNumber").isDirty
    // ) {
    //   setValue("productLotNumber", lotNumber);
    // }

    // Remove both manufacturing & expiry date
    // if (
    //   manufacturingDate &&
    //   typeof manufacturingDate === "string" &&
    //   !getFieldState("manufacturingDate").isDirty
    // ) {
    //   setValue("manufacturingDate", manufacturingDate);
    // }

    // if (
    //   expiryDate &&
    //   typeof expiryDate === "string" &&
    //   !getFieldState("expiryDate").isDirty
    // ) {
    //   setValue("expiryDate", expiryDate);
    // }
  });

  return (
    <Layout>
      <Modal show={show} fullscreen={true} onHide={handleClose}>
        <Modal.Body className={productListModal}>
          <StaticImage
            alt="eldon logo"
            src="../images/eldon-healthcare-logo.webp"
            style={{ width: "90px", height: "90px" }}
          />
          <p className="mt-3">
            <strong>Product Detail</strong>
          </p>
          <div className="mt-5">
            <p className={productListModalSelectProductModelText}>
              Please select the model
            </p>
            {stackProductGrid(products)}
          </div>
        </Modal.Body>
      </Modal>
      <div className={inputFormContainer}>
        <h5 className={headerTitle}>
          Welcome to Eldon Healthcare e-Warranty Registration
        </h5>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {selectedProductCard(selectedProduct, errors, handleShow)}
          <Controller
            name="productModelCode"
            control={control}
            defaultValue={""}
            rules={{
              required: { value: true, message: "Input is required" },
            }}
            render={({ field }) => (
              <Form.Control
                type="hidden"
                isInvalid={errors.productModelCode != null}
                value={field.value}
              />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="productModelCode"
            render={({ message }) => (
              <Form.Control.Feedback type="invalid">
                {message}
              </Form.Control.Feedback>
            )}
          />
          <Form.Group className="mt-3 mb-3" controlId="formProductLotNumber">
            <Form.Label className={formTitleLabel}>
              Product Serial No. <span className="text-muted" style={{fontSize: "0.75rem", fontStyle: "italic"}}>(Please key in without "SN")</span>
            </Form.Label>
            <Controller
              name="productLotNumber"
              control={control}
              rules={{
                required: { value: true, message: "Input is required" },
                // Not required regex check
                // pattern: { value: /^[0-9]+$/, message: "Incorrect format" },
              }}
              render={({ field }) => (
                <Form.Control
                  type="text"
                  size="lg"
                  {...field}
                  isInvalid={errors.productLotNumber != null}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="productLotNumber"
              render={({ message }) => (
                <Form.Control.Feedback type="invalid">
                  {message}
                </Form.Control.Feedback>
              )}
            />
          </Form.Group>
          {/* <Form.Group className="mb-3" controlId="formManufacturingDate">
            <Form.Label className={formTitleLabel}>
              Manufacturing date
            </Form.Label>
            <Controller
              name="manufacturingDate"
              control={control}
              rules={{
                required: { value: true, message: "Input is required" },
                pattern: {
                  value:
                    /^(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:(?:(\/|-|\.)(?:0?[13578]|1[02])\1(?:31))|(?:(\/|-|\.)(?:0?[13-9]|1[0-2])\2(?:29|30)))$| ^(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(\/|-|\.)0?2\3(?:29)$|^(?:(?:1[6-9]|[2-9]\d)?\d{2})(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:0?[1-9]|1\d|2[0-8])$/,
                  message: "Incorrect format",
                },
              }}
              render={({ field }) => (
                <Form.Control
                  type="date"
                  size="lg"
                  {...field}
                  isInvalid={errors.manufacturingDate != null}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="manufacturingDate"
              render={({ message }) => (
                <Form.Control.Feedback type="invalid">
                  {message}
                </Form.Control.Feedback>
              )}
            />
          </Form.Group> */}
          {/* <Form.Group className="mb-1" controlId="formExpiryDate">
            <Form.Label className={formTitleLabel}>Expiry date <span className="text-muted" style={{ fontSize: "0.75rem"}}>(Leave this box blank if not applicable)</span></Form.Label>
            <Controller
              name="expiryDate"
              control={control}
              rules={{
                pattern: {
                  value:
                    /^(?:(?:1[6-9]|[2-9]\d)?\d{2})(?:(?:(\/|-|\.)(?:0?[13578]|1[02])\1(?:31))|(?:(\/|-|\.)(?:0?[13-9]|1[0-2])\2(?:29|30)))$| ^(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00)))(\/|-|\.)0?2\3(?:29)$|^(?:(?:1[6-9]|[2-9]\d)?\d{2})(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:0?[1-9]|1\d|2[0-8])$/,
                  message: "Incorrect format",
                },
              }}
              render={({ field }) => (
                <Form.Control
                  type="date"
                  size="lg"
                  {...field}
                  isInvalid={errors.expiryDate != null}
                />
              )}
            />
            <ErrorMessage
              errors={errors}
              name="expiryDate"
              render={({ message }) => (
                <Form.Control.Feedback type="invalid">
                  {message}
                </Form.Control.Feedback>
              )}
            />
          </Form.Group> */}
          <Form.Text muted>
            Make sure that the model and information displayed above are correct
            before you proceed. Please click to edit if any of the fields is
            incorrect.
          </Form.Text>
          <div className="d-grid mt-5">
            <Button
              type="submit"
              size="lg"
              className={walnutPrimaryButton}
              // onClick={() =>
              //   setValue("productModelCode", selectedProduct?.code || "")
              // }
            >
              START REGISTRATION
            </Button>
          </div>
        </Form>
      </div>
    </Layout>
  );
};

export default IndexPage;
